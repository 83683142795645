import React from "react";
import { Route, Switch } from "react-router-dom";

import StripeWrapper from "@/components/StripeWrapper";
import ShortFunnelPayment from "./ShortFunnelPayment/ShortFunnelPayment";
import ShortFunnelSearch from "./ShortFunnelSearch";

export function ShortFunnel() {
  return (
    <Switch>
      <Route
        path={[
          "/gruppi_chiave_grigia/:service",
          "/gruppi_chiave_verde/:service",
          "/gruppo_non_disponibile/:service",
        ]}
      >
        <ShortFunnelSearch />
      </Route>
      <Route
        path={[
          "/checkout_chiave_verde/:service/post/:postId",
          "/checkout_chiave_grigia/:service/post/:postId",
        ]}
      >
        <StripeWrapper>
          <ShortFunnelPayment />
        </StripeWrapper>
      </Route>
    </Switch>
  );
}
