import { useDiscountedPostPrice } from "@/api/promo";
import { useSharingPost } from "@/api/shortFunnel";
import { SpinnerAdornment } from "@/components/CreateFlowSteps/ChoosePhone";
import ResponsiveSharingCard from "@/components/ResponsiveSharingCard";
import useSearchTitle from "@/hooks/search/useSearchTitle";
import { fetchNetwork } from "@bit/together-price.core.api.services";
import {
  useApp,
  useNavigateToUrl,
} from "@bit/together-price.core.components.app-context";
import Button from "@bit/together-price.core.components.button";
import PageWrapper from "@bit/together-price.core.components.page-wrapper";
import TCard from "@bit/together-price.core.components.tcard";
import { Grid, makeStyles } from "@material-ui/core";
import { useStripe } from "@stripe/react-stripe-js";
import React, { useCallback, useMemo, useState } from "react";
import { Trans } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  title: {},
  root: {
    flex: 1,
    justifyContent: "space-between",
    padding: theme.spacing(4),
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 32,
  },
  mobileInner: {
    padding: theme.spacing(1, 0),
  },
}));

export type SearchProps = {
  usePublicNetwork?: boolean;
  marketCountry?: string;
};
const ShortFunnelPayment: React.FC<SearchProps> = ({
  usePublicNetwork = false,
  marketCountry: defaultMarketCountry,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const navigateToUrl = useNavigateToUrl();
  const [paymentUUID, setPaymentUUID] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [title] = useSearchTitle(
    defaultMarketCountry,
    usePublicNetwork,
    "joinSubscription"
  );
  const postIdParts = location.pathname.split("/");
  const postId = postIdParts[postIdParts.length - 1];

  const { data: post } = useSharingPost(postId);

  const joiners = post?.joiners.map((joiner) => {
    return joiner;
  });

  const tags = post?.tags.map((tag) => {
    return tag;
  });

  const { setError } = useApp();
  const stripe = useStripe();

  const onBack = useCallback(() => {
    if (history.length > 0) {
      return history.goBack();
    }
    return history.push("/");
  }, [history]);

  const fullTopAppBarProps = useMemo(
    () => ({
      mobileProps: {
        title,
        classes: {
          title: classes.title,
        },
        onBack,
      },
    }),
    [onBack, title]
  );
  const pageClasses = useMemo(
    () => ({
      mobileInner: classes.mobileInner,
    }),
    [classes.mobileInner]
  );

  const checkStatus = async (uuid: string): Promise<"SUCCESS" | "FAILED"> => {
    const checkResponse = await fetchNetwork(
      `/requests/checkPaySharingRequests/${uuid}`
    );

    if (checkResponse.status === "SUBMITTED") {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return checkStatus(uuid);
    }

    if (checkResponse.status === "REQUIRES_ACTION") {
      await stripe.handleNextAction({
        clientSecret: checkResponse.clientSecret,
      });
      await fetchNetwork(`/requests/v2/confirmPayment/${uuid}`, {
        method: "POST",
      });
      return checkStatus(uuid);
    }

    return checkResponse.status === "ERROR" ||
      checkResponse.status === "EXPIRED"
      ? "FAILED"
      : "SUCCESS";
  };

  const [discountedPostPrice] = useDiscountedPostPrice(
    {
      monthlyPrice: post.monthlyPrice,
      feePrice: post.feePrice,
      frequency: post.frequency,
    },
    false
  );

  return (
    <>
      <PageWrapper
        isPublic={usePublicNetwork}
        activeTab="groups"
        navigateToUrl={navigateToUrl}
        FullTopAppBarProps={fullTopAppBarProps}
        classes={pageClasses}
      >
        <Grid className={classes.root} container direction="column">
          <Grid className={classes.cardContainer} item>
            {post && (
              <ResponsiveSharingCard
                post={{
                  sharingPostId: post.sharingPostId,
                  serviceId: post.serviceId,
                  postTitle: post.postTitle,
                  imagePath: post.imagePath,
                  secretStatus: post.secretStatus,
                  feePrice: {
                    value: post.feePrice.value,
                    currency: post.feePrice.currency,
                  },
                  monthlyPrice: {
                    value: post.monthlyPrice.value,
                    currency: post.monthlyPrice.currency,
                  },
                  frequency: post.frequency,
                  freeAvailability: post.freeAvailability,
                  totalAvailability: post.totalAvailability,
                  hasFreeSlots: post.hasFreeSlots,
                  admin: {
                    userId: post.admin.userId,
                    adminEmail: post.admin?.adminEmail,
                    adminFullname: post.admin.adminFullname,
                    imagePath: post.admin.imagePath,
                    verified: post.admin.verified,
                    trust: post.admin.trust,
                    cellphoneVerified: post.admin.cellphoneVerified,
                    emailVerified: post.admin.emailVerified,
                    lastSession: post.admin.lastSession,
                    ratings: post.admin.ratings,
                  },
                  tags: tags,
                  joiners: joiners,
                  category: post.category,
                  cratedAt: post.cratedAt,
                  marketCountry: post.marketCountry,
                }}
                sharingPostId={post.sharingPostId}
                title={post.postTitle}
                price={{
                  value: discountedPostPrice.value,
                  currency: discountedPostPrice.currency,
                }}
                frequency={post.frequency}
                freeAvailability={post.freeAvailability}
                secretStatus={post.secretStatus}
                admin={{
                  fullName: post.admin.adminFullname,
                  profilePictureUrl: post.admin.imagePath,
                  ratings: post.admin.ratings,
                  trust: post.admin.trust,
                  verified: post.admin.verified,
                }}
              />
            )}
            <TCard
              onSelect={(pm) => {
                if (pm.type === "CARD") setButtonDisabled(false);
              }}
            />
          </Grid>

          <Grid item>
            <Button
              onClick={async (e) => {
                e.preventDefault();
                try {
                  setIsLoading(true);
                  let uuid = paymentUUID;

                  if (!uuid) {
                    uuid = await fetchNetwork(
                      `/requests/pay/${post.sharingPostId}`,
                      {
                        method: "POST",
                      }
                    );
                    setPaymentUUID(uuid);
                  }

                  const status = await checkStatus(uuid);

                  if (status === "FAILED") {
                    throw new Error("Payment failed");
                  }

                  if (status === "SUCCESS") {
                    navigateToUrl(`/post/${post.sharingPostId}`);
                  }
                } catch (error) {
                  setError(error.message);
                } finally {
                  setIsLoading(false);
                }
              }}
              variant="contained"
              color="primary"
              disabled={buttonDisabled || isLoading}
              fullWidth
            >
              {isLoading ? (
                <SpinnerAdornment />
              ) : (
                <Trans i18nKey="Create.continue" />
              )}
            </Button>
          </Grid>
        </Grid>
      </PageWrapper>
    </>
  );
};

export default ShortFunnelPayment;
