import { MoneyLike } from '@bit/together-price.core.monetary';
import useSWR, { SWRConfiguration } from 'swr';
import {
  fetchNetwork,
  fetchRetention,
} from '@bit/together-price.core.api.services';
import { Fetcher } from '@bit/together-price.core.api.fetch';
import { useUserStoreFeatures } from '@bit/together-price.core.api.user';
import { useMemo } from 'react';
import { usePublic } from '@bit/together-price.core.components.app-context';
import {
  DiscountPostType,
  getDiscountedPostPrice,
  isDiscounted,
  PromoTag,
  PromoToken,
  ReferralPromo,
} from '@bit/together-price.core.utils.promo';

type AdvertisingBannerPayload = {
  country: string;
  language: string;
  siteContext: string;
  keywords: string | null;
};

export type AdvertisingBannerResponse = {
  banners: { images: string[]; link: string; platform: string }[];
  footers: any[];
  headers: any[];
};

export const fetchAdvertisingBanner = (
  payload: AdvertisingBannerPayload
): Promise<AdvertisingBannerResponse> =>
  fetchNetwork('/advertising/getBanner', {
    method: 'POST',
    body: JSON.stringify(payload),
  });

export const useReferralPromo = (): ReferralPromo =>
  useSWR(
    '/referral_promo/referralProgram',
    fetchRetention as Fetcher<ReferralPromo>,
    {
      suspense: true,
      dedupingInterval: 30000,
      refreshInterval: 300000,
    }
  ).data;

export type Promo = {
  activable?: boolean;
  active: boolean;
  details: {
    amount: number;
    isActivable: boolean;
    reedemCount: number;
    serviceAvailable: number[];
    serviceGroupTag: string[];
  };
};
export const useGetPromo = (tag?: PromoTag): Promo =>
  useSWR(
    tag && `/promo/get_user_promo?promo_tag=${tag}`,
    fetchRetention as Fetcher<Promo>,
    {
      suspense: false,
      dedupingInterval: 30000,
      refreshInterval: 300000,
    }
  ).data;

// TODO used only for banner?
export const usePromoToken = (active = true): PromoToken =>
  useSWR(
    () => (active ? '/hasPromoTokenToUse' : null),
    fetchRetention as Fetcher<PromoToken>,
    {
      suspense: false,
      dedupingInterval: 8000,
    }
  ).data;

export const useFetchPromoTokenToUseForFirstPayment = (
  active = true
): PromoToken[] =>
  useSWR(
    () => (active ? '/tokens/getPromoTokensForFirstPayment' : null),
    fetchRetention as Fetcher<PromoToken[]>,
    {
      suspense: false,
      dedupingInterval: 30000,
      refreshInterval: 300000,
      fallbackData: [],
    }
  ).data;

export const useFetchPromoTokenToUseForRenew = (active = true): PromoToken[] =>
  useSWR(
    () => (active ? '/tokens/getPromoTokensForRenew' : null),
    fetchRetention as Fetcher<PromoToken[]>,
    {
      suspense: false,
      dedupingInterval: 30000,
      refreshInterval: 300000,
      fallbackData: [],
    }
  ).data;

export const useCrossSellingPromoPending = (
  options: SWRConfiguration = {}
): PromoToken =>
  useSWR(
    '/cross_selling_promo/hasCrossSellingPromoInPending',
    fetchRetention as Fetcher<PromoToken>,
    {
      suspense: true,
      dedupingInterval: 8000,
      ...options,
    }
  ).data;

// the post price is discounted if `discountedPostPrice` is less then `post.monthlyPrice`

export const useDiscountedPostPrice = (
  post: DiscountPostType,
  noPromo?: boolean,
  isRenew = false
): [MoneyLike, boolean] => {
  const publicNetwork = usePublic();
  const promoToken = useFetchPromoTokenToUseForFirstPayment(
    !publicNetwork && !noPromo
  );
  const renewPromoToken = useFetchPromoTokenToUseForRenew(
    !publicNetwork && !noPromo
  );
  const { data: storeFeatures = [] } = useUserStoreFeatures({ suspense: false, fallbackData: [], dedupingInterval: 30000 });
  const hasNoFeeService = useMemo(
    () => storeFeatures.find(({ tag: itemTag }) => itemTag === 'NO_FEE'),
    [storeFeatures]
  );
  const discountedPostPrice = useMemo(() => {
    if (publicNetwork) return post.monthlyPrice;
    return getDiscountedPostPrice(
      post,
      [...renewPromoToken, ...promoToken].filter(Boolean),
      !!hasNoFeeService,
      isRenew
    );
  }, [
    post,
    promoToken,
    renewPromoToken,
    hasNoFeeService,
    publicNetwork,
    isRenew,
  ]);

  const discounted = useMemo(
    () => isDiscounted(discountedPostPrice, post.monthlyPrice),
    [discountedPostPrice, post]
  );

  return [discountedPostPrice, discounted];
};
